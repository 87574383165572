import { toast } from "react-toastify";
import { Method } from "../types";
import { createRequest } from "../utils/createRequest";
import { useRefreshToken } from "./useRefreshToken";

export function useRequest() {
  const { refresh } = useRefreshToken();

  return <R, P = unknown>(url: string, method: Method, payload?: P) =>
    refresh().then((accessToken) =>
      createRequest<R, P>(url, method, accessToken, payload).catch((error) => {
        return error?.json().then((e: any) => {
          toast.error(e.message);
          throw error;
        });
      })
    );
}
