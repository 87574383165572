import styled from '@emotion/styled/macro';
import React from 'react';

import { Spinner } from '../components/Spinner';

export function Loading() {
  return (
    <StyledLoading>
      <Spinner/>
    </StyledLoading>
  );
}

const StyledLoading = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;
