import { useDispatch } from "react-redux";
import { Login } from "../api/Login";
import { Method } from "../types";
import { createRequest } from "../utils/createRequest";
import { refresh as refreshAction } from "../store/slices/authSlice";
import { logout as logoutAction } from "../store/slices/authSlice";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { reset } from "../store/slices/sessionSlice";
import { Mutex } from "async-mutex";
import { RootState, store } from "../store/store";

const mutex = new Mutex();
export const SESSION_REFRESH_TIME = 5 * 60 * 1000;

export const useRefreshToken = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(reset());
    dispatch(logoutAction());
    history.push("/");
  };

  const refresh = useCallback(async () => {
    const release = await mutex.acquire();
    const auth = (store.getState() as RootState).auth;

    if (!auth.expiresOn) {
      release();
      return auth.accessToken;
    }

    if (
      new Date(auth.expiresOn).getTime() - Date.now() >
      SESSION_REFRESH_TIME
    ) {
      release();
      return auth.accessToken;
    }

    try {
      const response = await createRequest<Login["token"]>(
        "token/refresh",
        Method.Post,
        undefined,
        {
          refreshToken: auth.refreshToken,
        }
      );
      dispatch(refreshAction(response));
      return response.accessToken;
    } catch (error) {
      logout();
    } finally {
      release();
    }
  }, []);

  return {
    refresh,
  };
};
