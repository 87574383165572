import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { Colors } from '../../constants/colors';

import { useModal } from '../../hooks/useModal';
import { RootState } from '../../store/store';
import { Button } from './Button';

type Props = {
  children: ReactNode;
};

export function Modal({ children, ...props }: Props) {
  const { dismissModal } = useModal();
  const { style, showClose, modalActive } = useSelector(
    (state: RootState) => state.modal
  );

  const dismissClicked = (ev?: any) => {
    if (ev.target === ev.currentTarget) {
      dismissModal();
    }
  };

  return (
    <Wrapper visible={modalActive}>
      <ModalWrap>
        <ModalContainer
          classStyle={style || ModalStyles.standard}
          onClick={(ev) => dismissClicked(ev)}
        >
          <div className="modal">
            {children}
          </div>
        </ModalContainer>
      </ModalWrap>

      <Dismisser onClick={dismissClicked} />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99999999;
  box-sizing: border-box;

  animation: ${(props) =>
    props.visible ? 'fadeInUp 0.3s 1' : 'fadeOutDown 0.25s forwards'};
`;

const ModalWrap = styled.div`
  position: relative;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  text-align: center;
  transform: translate(0);
  z-index: 99;
  overflow: visible;

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

const ModalContainer = styled.div<{ classStyle: any }>`
  transform: translate(0);
  display: inline-block;
  width: 100%;
  box-sizing: border-box;

  .modal {
    margin: auto;
    position: relative;
    width: fit-content;
    max-width: -webkit-fill-available;
  }

  ${(props) => props.classStyle}
`;

const Dismisser = styled.div`
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  position: fixed;
  background-color: transparent;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

// MODAL STYLES
export const ModalStyles = {
  standard: css`
    .modal {
      background-color: ${Colors.bgBlank};
      text-align: left;
      width: 100%;
    }

    @media (min-width: 560px) {
      padding: 16px;
      .modal {
        border-radius: 12px;
        width: fit-content;
        > div:first-of-type {
          box-sizing: border-box;
          &:after {
            content: '';
            display: block;
            width: 9999px;
          }
        }
      }
    }
  `,
};
