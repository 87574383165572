import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Paginated } from '../api/Paginated';
import { Product } from '../api/Product';
import { User } from '../api/User';
import { loadInitialData, refreshLocations as refreshLocationsInternal } from '../store/slices/sessionSlice';
import { RootState } from '../store/store';
import { Method, Role } from '../types';
import { createRequest } from '../utils/createRequest';
import { isRole } from '../utils/isRole';
import { useAuth } from './useAuth';

export function useSession() {
  const { accessToken } = useAuth();
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.session);

  const init = async () => {
    const [unitTypes, products] = await Promise.all<any>([
      createRequest('unitTypes', Method.Get),
      createRequest('products', Method.Get)
    ]);
    const [me, allProducts, hiddenProducts, availableProducts] = await Promise.all([
      accessToken ? await createRequest<User>('me', Method.Get, accessToken) : undefined,
      accessToken ? await createRequest<Product[]>('products/all', Method.Get, accessToken) : undefined,
      accessToken ? await createRequest<Product[]>('products/all?state=2', Method.Get, accessToken) : undefined,
      accessToken ? await createRequest<Product[]>('products/all?state=0', Method.Get, accessToken) : undefined
    ]);
    const [company, locations] = await Promise.all([
      isRole(me, Role.Location)
        ? await createRequest<User>('location/company', Method.Get, accessToken)
        : undefined,
      isRole(me, Role.Company)
        ? await createRequest<Paginated<User>>('company/locations', Method.Get, accessToken)
        : undefined
    ]);

    dispatch(loadInitialData({
      me,
      unitTypes,
      products,
      allProducts,
      availableProducts,
      adminProducts: hiddenProducts ? [
        ...products,
        ...hiddenProducts
      ] : undefined,
      company,
      locations
    }));
  }

  const refreshLocations = async () => {
    const locations = isRole(session.me, Role.Company)
      ? await createRequest<Paginated<User>>('company/locations', Method.Get, accessToken)
      : undefined
    ;
    dispatch(refreshLocationsInternal(locations));
  }
  
  return {
    init,
    refreshLocations,
    session,
  }
}
