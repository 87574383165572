import styled from '@emotion/styled/macro';
import React, { HTMLProps } from 'react';

type Props = {
  gutter?: number
};

export function Row({ gutter = 0, ...props }: Props & HTMLProps<HTMLDivElement>) {
  return (
    <StyledRow gutter={gutter} {...props}/>
  );
}

const StyledRow = styled.div<{ gutter: number }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${(props) => props.gutter}rem;
  > * {
    padding: 0 ${(props) => props.gutter}rem;
  }
`;
