import React, { ComponentType, lazy } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAuth } from '../hooks/useAuth';
import { Webflow_API_URL } from '../constants';

type Props = RouteProps & {
  publicOnly?: boolean,
  external?: boolean,
  load?: () => Promise<{ default: ComponentType }>
};

export function PublicRoute({ publicOnly = false, load, external, ...route }: Props) {
  const authState = useAuth();

  if (external) {
    window.location.href = `${Webflow_API_URL}${route.path}`;
    return null; 
  }

  const LazyComponent = load ? lazy(load) : undefined;

  return (
    <Route
      {...route}
      exact={true}
      component={!authState.accessToken || !publicOnly ? LazyComponent : () => (
        <Redirect to="/admin" />
      )}
    />
  );
}
