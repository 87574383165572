export enum Colors {
  header = '#84c42c',
  textBody = '#28769C',
  textRegular = '#FFFFFF',
  textHard = '#012C41',
  textHeavy = '#00344E',
  sessionGradientStart = '#000000',
  sessionGradientEnd = 'rgba(255, 255, 255, 0)',
  headerGradientStart = '#0C0B0B',
  headerGradientEnd = 'rgba(12, 11, 11, 0)',
  popupBackdrop = 'rgba(0,0,0,0.3)',
  lineLight = '#CEDFE3',
  lineHighlight = '#DEEDF1',
  lineMain = '#DDDDDD',
  bgLight = '#FAFEFE',
  descriptionBg = '#FAFEFE',
  disabledBlue = '#CEDFE3',
  highlight = '#F0F9FB',
  headingBlue = '#1D5672',
  headerBlueLight = '#1D5672',
  outlineBlue = '#DEEDF1',
  outlineLight = '#F7FAFB',
  white = '#FFFFFF',
  black = '#000000',
  darkGreen = '#244030',
  menuGreen = '#3b6149',

  primary = '#012C41',
  action = '#84c42c',

  txtBlank = '#FFFFFF',
  txtBlankInvert = '#000000',
  txtBody = '#28769C',
  txtHard = '#012C41',
  txtHeavy = '#00344E',

  line = '#ececec',
  lineSoft = '#eef2f3',
  lineHard = '#CEDFE3',
  cardLine = '#DEECEF',

  bgBlank = '#FFFFFF',
  bgSoft = '#FAFEFE',
  bgHard = '#FAFEFE',
  bgHighlight = '#F0F9FB',
  bgHover = '#f5f5f5',
  bgGreenLight = '#f9fcf5',

  warning = '#DD0303',
  warningSoft = '#FF9029',

  error = '#E65527',
  errorSoft = '#FFE1E1',

  inputBg = '#fff',
  inputLine = '#DEECEF',
  inputLineHighlight = '#aed2db',
  inputColor = '#012C41',
  inputColorSoft = '#6fa2ad',
  inputLabel = '#86A6AF',
  inputToggleBg = '#d6d7db',

  iconRegular = '#000000',
  iconToolBg = '#EFEFEF',
  switchOff = '#D9D9D9',
}
