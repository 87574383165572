import React, { Suspense, useEffect, useState } from 'react';
import { lazily } from 'react-lazily';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from '../../hooks/useModal';
import { RootState } from '../../store/store';

import { Modal } from './Modal';

export function ModalRoot() {
  const dispatch = useDispatch();
  const { clearModal } = useModal();
  const { type, data, modalActive } = useSelector(
    (state: RootState) => state.modal
  );
  const resetTimerMs = 500; // TODO use animation library with callback function
  const [DynamicComponent, setDynamicComponent] = useState<any>();

  useEffect(() => {
    const lazyComponent = lazily(() => import(`../stateful/modals/${type}`));
    const dynamicComponent = lazyComponent[type];
    setDynamicComponent(dynamicComponent);
  }, [type]);

  useEffect(() => {
    let timeOut: any;
    if (!modalActive) {
      timeOut = setTimeout(() => {
        clearModal();
      }, resetTimerMs);
    }

    return () => {
      window.clearTimeout(timeOut);
    };
  }, [dispatch, modalActive]);

  if (type === '') return <></>;
  return (
    <Modal>
      <Suspense fallback={''}>
        {DynamicComponent && <DynamicComponent data={data} />}
      </Suspense>
    </Modal>
  );
}
