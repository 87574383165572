import React, { Suspense } from 'react';
import { Switch } from 'react-router';

import { Navigation } from '../components/Navigation';
import { PrivateRoute } from '../components/PrivateRoute';
import { Role } from '../types';

import { Loading } from './Loading';
import Settings from './Settings';

export default function Admin() {
  return (
    <>
      <Navigation />
      <Suspense fallback={<Loading />}>
        <Switch>
          <PrivateRoute
            path="/admin"
            exact={true}
            load={() => import('./Dashboard')}
          />
          <PrivateRoute
            path="/admin/locations"
            exact={true}
            load={() => import('./Locations')}
            roles={[Role.Admin, Role.Company]}
          />
          <PrivateRoute
            path="/admin/amount"
            exact={true}
            load={() => import('./Amount')}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path="/admin/finalize"
            exact={true}
            load={() => import('./Finalize')}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path="/admin/products"
            exact={true}
            load={() => import('./Products')}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path="/admin/prices"
            exact={true}
            load={() => import('./Prices')}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path="/admin/companies"
            exact={true}
            load={() => import('./Company')}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path="/admin/delivery"
            exact={true}
            load={() => import('./Delivery')}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path="/admin/send-email"
            exact={true}
            load={() => import('./SendEmail')}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path="/admin/invoices"
            exact={true}
            load={() => import('./Invoices')}
            roles={[Role.Admin]}
          />
          <PrivateRoute
            path="/admin/settings"
            exact={true}
            component={Settings}
          />
        </Switch>
      </Suspense>
    </>
  );
}
