import React, { ComponentProps, useState } from 'react';
import ReactSwitch from 'react-switch';
import styled from '@emotion/styled';
import { GREEN } from '../../constants';
import { Colors } from '../../constants/colors';

type Props = {
  size?: number;
} & ComponentProps<typeof ReactSwitch>;

export function Switch({
  size = 26,
  ...props
}: Props) {
  return (
    <ReactSwitch
      {...props}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor={props?.disabled ? Colors.switchOff : GREEN}
      offColor={Colors.switchOff}
      handleDiameter={size}
      height={Math.ceil(size * 1.2)}
      width={size * 2}
    />
  );
}
