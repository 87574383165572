import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { PersistConfig } from 'redux-persist';
import persistReducer from 'redux-persist/lib/persistReducer';
import persistStore from 'redux-persist/lib/persistStore';

import auth from './slices/authSlice';
import modal from './slices/modalSlice';
import session from './slices/sessionSlice';
import AsyncStorage from './storage';

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage: AsyncStorage,
  timeout: 0,
  version: 0,
  whitelist: ['session', 'auth'],
};

const rootReducer = combineReducers({
  modal,
  session,
  auth
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (defaultMiddleware) => defaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
    thunk: true,
  }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
