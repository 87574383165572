import { User } from '../api/User';
import { Role } from '../types';

export function switchRole<T>(me: User | undefined, obj: { [R in Role]?: T }): T | undefined {
  if (!me) {
    return;
  }

  for (const role in obj) {
    if (role !== me.role) {
      continue;
    }

    return obj[role];
  }
}
