import { useEffect } from 'react';
import { Key as Keyboard } from 'ts-key-enum';

export function useKeyHandler(key: Keyboard, handler: () => void) {
  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === key) {
      handler();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, []);
}
