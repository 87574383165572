import { keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { GREEN } from '../constants';

export function Spinner() {
  return (
    <StyledSpinner/>
  );
}

const spin = keyframes`
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
`;

const StyledSpinner = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 3px solid #ececec;
  border-left-color: ${GREEN};
  animation: ${spin} .5s linear infinite;
`;
