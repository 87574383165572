import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Login } from '../../api/Login';

export type AuthState = {
  accessToken?: string,
  refreshToken?: string,
  expiresOn?: number
};

const initialState: AuthState = {
  accessToken: undefined,
  refreshToken: undefined,
  expiresOn: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Login>) => {
      const { accessToken, refreshToken, expiresIn } = action.payload.token;
      const expiresOn = Date.now() + Math.floor(expiresIn);
      return {
        accessToken,
        refreshToken,
        expiresOn
      };
    },
    logout: (state) => {
      return {};
    },
    refresh: (state, action: PayloadAction<Login['token']>) => {
      const { accessToken, refreshToken, expiresIn } = action.payload;
      const expiresOn = Date.now() + Math.floor(expiresIn);

      return {
        accessToken,
        refreshToken,
        expiresOn
      };
    }
  }
});

export const {
  login,
  logout,
  refresh,
} = authSlice.actions;

export default authSlice.reducer;
