import { css } from '@emotion/react';
import { Colors } from '../constants/colors';
import { Icons } from '../constants/icons';

const blank = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 0.1s ease-out;
  padding: 0;
`;

const primary = css`
  ${blank}
  height: 44px;
  padding: 8px 20px;
  border-radius: 32px;
  font-weight: 400;
  background-color: ${Colors.white};
  color: black;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.075);
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }

  &.active {
    background-color: red;
  }

  &:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }

  span {
    white-space: nowrap;
  }

  .icon {
    display: block;
    background-color: ${Colors.iconRegular};
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
`;

const text = css`
  ${blank}
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  white-space: nowrap;

  &.active {
    color: ${Colors.action}
  }
`;

const icon = css`
  ${blank};
  font-size: 0;
  background-color: transparent;
  height: 32px;
  width: 32px;

  .icon {
    display: block;
    background-color: ${Colors.white};
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 16px;
    height: 100%;
    width: 100%;
  }
`;

const close = css`
  ${blank};
  font-size: 0;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: ${Colors.inputToggleBg};

  .icon {
    background-color: ${Colors.inputColor};
    mask-image: url(${Icons.close});
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 10px;
    height: 26px;
    width: 26px;
  }
`;

const iconOnly = css`
  ${icon};
  height: 24px;
  width: 24px;

  .icon {
    background-color: ${Colors.black};
    mask-size: 18px;
    height: 100%;
  }

  &.warning {
    .icon {
      background-color: ${Colors.warning};
    }
  }
`;

const iconTool = css`
  ${icon};
  height: 38px;
  width: 38px;
  background-color: ${Colors.iconToolBg};
  border-radius: 50%;

  .icon {
    background-color: ${Colors.black};
    mask-size: 16px;
  }
`;

const primaryAction = css`
  ${primary};
  background-color: ${Colors.action};
  color: ${Colors.white};
`;

const ButtonStyles = {
  primary,
  primaryAction,
  icon,
  text,
  close,
  iconOnly,
  iconTool,
};

export default ButtonStyles;
