import React, { ComponentType, lazy, ReactNode } from 'react';
import { boundary, useError } from 'react-boundary';
import { Route, RouteProps } from 'react-router';

import { Loading } from '../containers/Loading';
import { useAuth } from '../hooks/useAuth';
import { useSession } from '../hooks/useSession';
import { Role } from '../types';
import { isRole } from '../utils/isRole';

type Props = RouteProps & {
  roles?: Role[],
} & ({
  load: () => Promise<{ default: ComponentType }>,
  component?: undefined
} | {
  component: ReactNode,
  load?: undefined,
});

const allRoles = [Role.Location, Role.Company, Role.Admin];

export const PrivateRoute = boundary(function PR({ load, roles = allRoles, component, ...route }: Props) {
  const authState = useAuth();
  const { session: sessionState } = useSession();
  const [error] = useError();

  if (authState.accessToken && !sessionState.me) {
    return (
      <Loading/>
    );
  }

  return (
    <Route
      {...route}
      component={error ? () => (
        <span>Oops! Er is iets mis gegaan.</span>
      ) : authState.accessToken && isRole(sessionState.me, ...roles) ? load ? lazy(load) : component : () => (<></>
        // <Redirect to={authState.accessToken ? '/admin' : '/'}/>
      )}
    />
  );
});
