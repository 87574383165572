import styled from '@emotion/styled/macro';
import React, { ReactNode } from 'react';

import { Breakpoint } from '../types';

import { Button } from './Button';
import { Column } from './Column';
import { Row } from './Row';

type Props = Record<'sidebar' | 'content', {
  title: string,
  actions: Array<{
    children: ReactNode,
    onClick: () => void,
    disabled?: boolean
  }>,
  children: ReactNode
}> & {
  offset?: number
};

export function Sider({ sidebar, content, offset = 0 }: Props) {
  return (
    <Row gutter={1}>
      <Column breakpoints={{ [Breakpoint.Phone]: 4 }}>
        <StyledSider offset={offset}>
          <StyledHeading>
            <strong>{sidebar.title}</strong>
            <StyledActions>
              {sidebar.actions.map((action, index) => (
                <Button key={index} disabled={action.disabled} onClick={action.onClick}>
                  {action.children}
                </Button>
              ))}
            </StyledActions>
          </StyledHeading>
          <StyledScrollable>
            {sidebar.children}
          </StyledScrollable>
        </StyledSider>
      </Column>
      <Column breakpoints={{ [Breakpoint.Phone]: 8 }}>
        <StyledSider offset={offset}>
          <StyledHeading>
            <strong>{content.title}</strong>
            <StyledActions>
              {content.actions.map((action, index) => (
                <Button key={index} disabled={action.disabled} onClick={action.onClick}>
                  {action.children}
                </Button>
              ))}
            </StyledActions>
          </StyledHeading>
          <StyledScrollable>
            {content.children}
          </StyledScrollable>
        </StyledSider>
      </Column>
    </Row>
  );
}

const StyledSider = styled.div<{ offset: number }>`
  border-radius: .75rem;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
  height: calc(100vh - 10rem - ${(props) => props.offset}rem);
`;

const StyledScrollable = styled.div`
  height: calc(100% - 5rem);
  overflow-y: scroll;
`;

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 5rem;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`;

const StyledActions = styled.div`
  * + * {
    margin-left: 1rem;
  }
`;
