import { useDispatch, useSelector } from 'react-redux';
import {
  dismissModalSliceFn,
  showModalSliceFn,
  clearModalSliceFn,
} from '../store/slices/modalSlice';
import { RootState } from '../store/store';

export function useModal() {
  const dispatch = useDispatch();

  const modalActive = useSelector(
    (state: RootState) => state.modal.modalActive
  );

  const showModal = (modal: {
    type: string;
    data: any;
    style?: string;
    showClose?: boolean;
  }) => {
    dispatch(showModalSliceFn(modal));
  };

  const dismissModal = () => {
    dispatch(dismissModalSliceFn());
  };

  const clearModal = () => {
    dispatch(clearModalSliceFn());
  };

  return { showModal, dismissModal, clearModal, modalActive };
}
