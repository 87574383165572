import 'nanoreset';
import React from 'react';
import { render } from 'react-dom';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';

import { Root } from './containers/Root';
import { OverlayProvider } from './states/overlay';
import { store, persistor } from './store/store';
import { ModalRoot } from './components/common/ModalRoot';
import { Backdrop } from './components/common/Backdrop';
import { PersistGate } from 'redux-persist/integration/react';
import { Loading } from './containers/Loading';

toast.configure({
  transition: Slide,
  hideProgressBar: true,
  closeButton: false,
  position: 'bottom-left'
});

render((
  <Provider store={store}>
    <PersistGate loading={<Loading/>} persistor={persistor}>
      <OverlayProvider>
        <Root />
      </OverlayProvider>
      <ModalRoot />
      <Backdrop />
    </PersistGate>
  </Provider>
), document.getElementById('root'));
