import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { ReactNode } from 'react';

import { GREEN } from '../constants';

import { Placeholder } from './Placeholder';

type Props = {
  value?: string,
  label?: ReactNode,
  enabled?: boolean,
  onClick?: (key: string) => void
};

export function Entry({ value, label, enabled, onClick }: Props) {
  return (
    <StyledEntry
      enabled={!!enabled}
      disabled={!value || !onClick}
      onClick={() => value && onClick && onClick(value)}
    >
      {label || value || <Placeholder/>}
    </StyledEntry>
  );
}

const StyledEntry = styled.div<{ enabled: boolean, disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  padding: 0 2rem;
  border: 1px solid rgba(0, 0, 0, .1);
  border-top: none;
  border-left: none;
  transition: color .25s ease, box-shadow .25s ease, border .4s ease;
  ${(props) => props.enabled && css`
    color: ${GREEN};
    border-left: solid 0.4rem ${GREEN};
    font-weight: bolder;
  `};
  ${(props) => !props.disabled && css`
    :hover {
      box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, .25);
      cursor: pointer;
    }
  `};
`;
