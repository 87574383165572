import { Validator } from '../types';

export async function validate<V extends object>(values: V, schema: { [K in keyof V]?: Array<Validator<V>> }) {
  let result = {};

  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      const value = String(values[key]);
      const validators = schema[key] as Array<Validator<V>> | undefined;

      if (!validators) {
        continue;
      }

      for (const validator of validators) {
        if (!await validator[0](value, values)) {
          result = {
            ...result,
            [key]: validator[1]
          };

          break;
        }
      }
    }
  }

  return result;
}
