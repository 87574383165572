import styled from '@emotion/styled';
import React, { ComponentProps } from 'react';
import ButtonStyles from '../../styles/ButtonStyles';

type Props = {
  show?: boolean;
  value: string;
  icon?: string;
  iconSize?: string;
  color?: string;
  background?: string;
  styleClass?: any;
  extraClass?: string;
  space?: string;
  full?: boolean;
  inline?: boolean;
  center?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
} & ComponentProps<'div'>;

export function Button({
  show = true,
  value,
  icon,
  iconSize,
  background,
  type = 'button',
  color,
  full,
  space,
  inline = false,
  center = false,
  loading = false,
  disabled = false,
  styleClass = ButtonStyles.primary,
  extraClass = '',
  ...props
}: Props) {
  if (!show) {
    return <></>;
  }

  return (
    <Container {...props} space={space} full={full} inline={inline}>
      <ButtonStyled
        icon={icon}
        type={type}
        disabled={disabled || loading}
        // loading={loading}
        full={full}
        center={center}
        iconSize={iconSize}
        color={color}
        background={background}
        styleClass={styleClass}
        className={extraClass}
      >
        {icon && <span className="icon"></span>}
        <span>{value}</span>
      </ButtonStyled>
    </Container>
  );
}

const Container = styled.div<{
  space?: string;
  full?: boolean;
  inline?: boolean;
}>`
  margin: ${(props) => (props.space ? props.space : '')};
  width: ${(props) => (props.full ? '100%' : 'auto')};
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
`;

const ButtonStyled = styled.button<{
  full?: boolean;
  loading?: boolean;
  disabled?: boolean;
  icon?: string;
  iconSize?: string;
  color?: string;
  background?: string;
  styleClass?: any;
  center?: boolean;
}>`
  width: ${(props) => (props.full ? '100%' : 'auto')};
  ${(props) => (props.loading ? 'color: transparent' : '')};
  ${(props) => props.center && 'margin: 0 auto'};

  .icon {
    ${(props) => props.icon ? 'mask: url(' + props.icon + ') no-repeat center;' : ''};
    ${(props) => (props.icon && props.iconSize) ? `mask-size: ${props.iconSize} !important` : ''};
  }

  ${(props) => props.styleClass}

  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.background && `background-color: ${props.background}`};
`;
