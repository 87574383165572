import styled from '@emotion/styled/macro';
import React, { HTMLAttributes } from 'react';

import { BREAKPOINTS } from '../constants';
import { forBreakpoints } from '../utils/forBreakpoints';

export function Container(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <StyledContainer {...props}/>
  );
}

const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0 1rem 2rem;
  ${forBreakpoints(BREAKPOINTS, (value) => `
    width: calc(${value ? `${value}px - 1rem` : '100%'} - 2rem);
  `)};
`;
