import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  style: '',
  showClose: true,
  data: null,
  modalActive: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModalSliceFn(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        modalActive: true,
      };
    },
    dismissModalSliceFn(state) {
      return {
        ...state,
        modalActive: false,
      };
    },
    clearModalSliceFn() {
      return {
        type: '',
        style: '',
        showClose: true,
        data: null,
        modalActive: false,
      };
    },
  },
});

export const { showModalSliceFn, dismissModalSliceFn, clearModalSliceFn } = modalSlice.actions;

export default modalSlice.reducer;
