import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { ComponentProps } from 'react';

import { GREEN, GREEN_DARKENED } from '../constants';

type ButtonMode = keyof typeof modeStyles;
type ButtonTheme = keyof typeof themeStyles;

type Props = ComponentProps<'button'> & {
  mode?: ButtonMode,
  theme?: ButtonTheme,
  compact?: boolean,
  expand?: boolean
};

export function Button({ mode = 'button', theme = 'light', compact = false, expand = false, ...props }: Props) {
  return (
    <StyledButton mode={mode} theme={theme as never} compact={compact} expand={expand} {...props} />
  );
}

const modeStyles = {
  button: css`
    padding: .75rem 1.5rem;
    border-radius: 5rem;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25);
    :hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
    }
  `,
  text: css`
    padding: 0;
    background-color: transparent !important;
  `,
  iconOnly: css`
    padding: 0;
    background-color: transparent;
    border-radius: 50%;
  `
};

const themeStyles = {
  light: css`
    background-color: white;
    color: rgba(0, 0, 0, .5);
    :hover {
      color: rgba(0, 0, 0, 1);
    }
  `,
  dark: css`
    background-color: dimgray;
    color: rgba(255, 255, 255, .75);
    :hover {
      color: rgba(255, 255, 255, 1);
    }
  `,
  accent: css`
    background-color: ${GREEN};
    color: rgba(255, 255, 255, 1);
    :hover {
      background-color: ${GREEN_DARKENED};
    }
  `,
  grey: css`
    background-color: #f8f8f8;
    color: black;
  `,
  lightContrast: css`
    background-color: white;
    color: rgba(0, 0, 0, 1);
    :hover {
      background-color: #efefef;
    }
  `,
};

const StyledButton = styled.button<{ mode?: any, theme?: any, compact?: boolean, expand?: boolean }>`
  display: inline-flex;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  transition: color .25s ease, box-shadow .25s ease, background-color .25s ease;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  :disabled {
    pointer-events: none;
    opacity: .6;
    box-shadow: none;
  }
  ${(props) => themeStyles[props.theme]};
  ${(props) => modeStyles[props.mode]};
  ${(props) => props.compact && css`
    padding: .25rem .75rem;
  `};
  ${(props) => props.expand && css`
    width: 100%;
  `};
`;
