enum Icons {
  edit = '/images/icons/common/ic-edit.svg',
  search = '/images/icons/common/ic_search.svg',
  delete = '/images/icons/common/ic_delete.svg',
  add = '/images/icons/common/ic_add.svg',
  lock = '/images/icons/common/ic_lock.svg',
  block = '/images/icons/common/ic_block.svg',
  logout = '/images/icons/common/ic_logout.svg',
  check = '/images/icons/common/ic_check.svg',
  close = '/images/icons/common/ic_close.svg',
  chevronUp = '/images/icons/common/ic_chevronup.svg',
  chevronDown = '/images/icons/common/ic_chevrondown.svg',
  chevronDownWhite = '/images/icons/common/ic_chevrondown_white.svg',
  chevronRight = '/images/icons/common/ic_chevronright.svg',
  chevronLeft = '/images/icons/common/ic_chevronleft.svg',
  alert = '/images/icons/common/ic_alert.svg',
  warning = '/images/icons/common/ic_warning.svg',

  delivery = '/images/icons/delivery.svg',
  print = '/images/icons/print.svg',
  note = '/images/icons/note.svg',
  
  packagingTodo = '/images/icons/ic_emballage_todo.svg',
  packagingDone = '/images/icons/ic_emballage_done.svg',

  logoDark= '/images/icons/logo-dark.svg'
}

export { Icons };
