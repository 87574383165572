import { lazy } from 'react';

import { Breakpoint, DayState, DeliveryDay, LinkStatus, OrderState, ProductState } from './types';

let env = process.env.REACT_APP_ENV;

if (!env || env.indexOf('-') !== -1) {
  env = 'develop';
}

const config = require(`./config/${env}`).default;

export const API_URL = config.api;
export const Webflow_API_URL = config.webflowApi;
export const RESOURCE_URL = config.resource;

export const BREAKPOINTS = {
  [Breakpoint.Phone]: 0,
  [Breakpoint.TabletPortrait]: 600,
  [Breakpoint.TabletLandscape]: 900,
  [Breakpoint.Desktop]: 1200,
  [Breakpoint.WebFlowTablet]: 990,
};

export const UNKNOWN = 'In behandeling';

export const GREEN = '#84c42c';
export const GREEN_DARKENED = '#7cb829';
export const SUBTITLE_GREEN = 'rgba(36, 64, 48, .8)';

export const OVERLAYS = {
  order: lazy(() => import('./overlays/Order')),
  details: lazy(() => import('./overlays/Details')),
  location: lazy(() => import('./overlays/Location')),
  product: lazy(() => import('./overlays/Product')),
  navigation: lazy(() => import('./overlays/Navigation')),
  unit: lazy(() => import('./overlays/UnitTypes')),
  addProduct: lazy(() => import('./overlays/AddProduct')),
  addCompany: lazy(() => import('./overlays/AddCompany')),
  packaging: lazy(() => import('./overlays/Packaging')),
  printInvoice: lazy(() => import('./overlays/PrintInvoice'))
};

export const PRODUCT_STATES: Record<ProductState, { title: string, color: string }> = {
  [ProductState.Published]: {
    title: 'Gepubliceerd',
    color: GREEN
  },
  [ProductState.SoldOut]: {
    title: 'Onbeschikbaar',
    color: 'orange'
  },
  [ProductState.Invisible]: {
    title: 'Verborgen',
    color: 'red'
  },
  [ProductState.Deleted]: {
    title: 'Verwijderd',
    color: 'gray'
  }
};

export const ORDER_STATES: Record<OrderState, { title: string, color: string }> = {
  [OrderState.Draft]: { title: 'Geplaatst', color: 'red' },
  [OrderState.Final]: { title: 'In behandeling', color: 'orange' },
  [OrderState.Processed]: { title: 'Verwerkt', color: 'green' },
  [OrderState.Rejected]: { title: 'Afgewezen', color: 'gray' },
  [OrderState.Cancelled]: { title: 'Geannuleerd', color: 'gray' }
};

export const LINK_STATES: Record<LinkStatus, { title: string, color: string }> = {
  [LinkStatus.Pending]: { title: 'In afwachting', color: 'orange' },
  [LinkStatus.Accepted]: { title: 'Actief', color: 'green' },
  [LinkStatus.Rejected]: { title: 'Afgewezen', color: 'red' }
};

export const DAY_STATES: Record<DayState, { title: string, color: string }> = {
  [DayState.Open]: { title: 'Geopend', color: 'green' },
  [DayState.Closed]: { title: 'Gesloten', color: 'red' }
};

export const DELIVERY_DAYS: Record<DeliveryDay, { title: string }> = {
  [DeliveryDay.Monday]: { title: 'Maandag'},
  [DeliveryDay.Tuesday]: { title: 'Dinsdag'},
  [DeliveryDay.Wednesday]: { title: 'Woensdag'},
  [DeliveryDay.Thursday]: { title: 'Donderdag'},
  [DeliveryDay.Friday]: { title: 'Vrijdag'}
};
