import { css, Global } from '@emotion/core';
import React, { useEffect, useState } from 'react';

import { GREEN } from '../constants';

export function Styles() {
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', () => setFocus(false));
    document.addEventListener('keydown', () => setFocus(true));
  }, []);

  return (
    <Global
      styles={css`
        svg {
          pointer-events: none;
        }
        html {
          font-size: 14px;
          font-family: 'Open Sans', sans-serif;
          background-color: #f8f8f8;
          height: 100vh;
          overflow-y: scroll;
          overflow-x: hidden;
        }
        *:focus:not([id]) {
          outline: none;
          ${focus && `
            box-shadow: 0 0 0 2px #545454 !important
          `};
        }
        ::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
        ::-webkit-scrollbar-button {
          display: none;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${GREEN};
        }
        ::-webkit-scrollbar-track {
          background-color: #ececec;
        }
        .Toastify__toast {
          min-height: 4rem;
          padding: 1rem;
          border-radius: .75rem;
          &--success {
            background-color: ${GREEN};
          }
        }

        @keyframes fadeInUp {
          0% {
            display: none;
            opacity: 0;
            transform: translate(0, 20px) scale(1);
          } // start state
          1% {
            display: block;
            opacity: 0;
            transform: translate(0, 20px) scale(1);
          } // start state
          100% {
            display: block;
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0) scale(1);
          } // end state
        }
        @keyframes fadeOutDown {
          0% {
            display: block;
            opacity: 1;
            transform: translate(0, 0) scale(1);
          } // start state
          1% {
            display: block;
            opacity: 1;
            transform: translate(0, 0) scale(1);
          } // start state
          100% {
            display: none;
            opacity: 0;
            visibility: hidden;
            transform: translate(0, 20px) scale(1);
          } // end state
        }

      `}
    />
  );
}
