import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { PrivateRoute } from '../components/PrivateRoute';
import { PublicRoute } from '../components/PublicRoute';
import { useAuth } from '../hooks/useAuth';
import { useSession } from '../hooks/useSession';
import Admin from './Admin';

import { Loading } from './Loading';
import { Overlays } from './Overlays';
import { Styles } from './Styles';

export function Root() {
  const authState = useAuth();
  const { init } = useSession();

  useEffect(() => {
    init();
  }, [authState.accessToken]);

  return (
    <>
      <Styles/>
      <BrowserRouter>
        <Suspense fallback={<Loading/>}>
          <Overlays/>
          <Switch>
            <PrivateRoute path="/admin" component={Admin} />
            <PublicRoute path="/register" exact={true} publicOnly={true} load={() => import('./Register')}/>
            <PublicRoute path="/reset" exact={true} publicOnly={true} load={() => import('./ResetPassword')}/>
            <PublicRoute path="/reset/:key" exact={true} publicOnly={true} load={() => import('./ResetPassword')}/>
            <PublicRoute path="/" load={() => import('./Public')}/>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}
