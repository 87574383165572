import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { useModal } from '../../hooks/useModal';

export function Backdrop() {
  const { modalActive } = useModal();

  return (
    <>
      <ContainerDismisser className={`${modalActive && 'active'}`} />
      <BackdropContainer visible={modalActive} />
    </>
  );
}

const ContainerDismisser = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 99999;
  display: none;
  top: 0;
  &.active {
    display: block;
  }
`;

const BackdropContainer = styled.div<{ visible: boolean }>`
  --opacityEnd: 0.4;

  background: black;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999999;
  position: fixed;
  opacity: var(--opacityEnd);

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: var(--opacityEnd);
      visibility: visible;
    }
  }
  @keyframes fadeOut {
    0% {
      display: block;
      opacity: var(--opacityEnd);
    }
    1% {
      display: none;
      opacity: var(--opacityEnd);
    }
    100% {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }

  animation: ${(props) =>
    props.visible ? 'fadeIn 0.4s 1' : 'fadeOut 0.4s forwards'};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;
