import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Paginated } from '../../api/Paginated';
import { Product } from '../../api/Product';
import { UnitType } from '../../api/UnitType';
import { User } from '../../api/User';
import { ProductState } from '../../types';

export type SessionState = {
  me?: User,
  unitTypes?: UnitType[],
  products?: Product[],
  adminProducts?: Product[],
  availableProducts?: Product[],
  allProducts?: Product[],
  company?: User,
  locations?: Paginated<User>
};

const initialState = {
  me: undefined,
  unitTypes: undefined,
  products: undefined,
  adminProducts: undefined,
  allProducts: undefined,
  company: undefined,
  locations: undefined,
};

const updateAvailableProducts = (availableProducts: Product[] | undefined, payload: Product) => {
  if (availableProducts) {
    if (payload.state === ProductState.Deleted || payload.state === ProductState.SoldOut || payload.state === ProductState.Invisible) {
      return availableProducts.filter((p) => p._id !== payload._id);
    }
    if (availableProducts.filter((p) => p._id === payload._id).length === 0) {
      return [...availableProducts, payload]
    }
    return availableProducts.map((p) => p._id !== payload._id ? p : payload);
  }
}
const updateProductList = (products: Product[] | undefined, updatedProduct: Product) => {
  if (!products) return products;
  const filteredProducts = products.filter((p) => p._id !== updatedProduct._id);
  if (updatedProduct.state !== ProductState.Deleted) {
    filteredProducts.push(updatedProduct);
  }
  return filteredProducts;
};


const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState as SessionState,
  reducers: {
    loadInitialData: (state, action: PayloadAction<SessionState>) => {
      return action.payload;
    },
    reset: () => initialState,
    patchProduct: (state, action: PayloadAction<Product>) => ({
      ...state,
      products: state.products && (action.payload.state !== ProductState.Deleted
        ? state.products.map((p) => p._id !== action.payload._id ? p : action.payload)
        : state.products.filter((p) => p._id !== action.payload._id)),
      allProducts: state.allProducts && state.allProducts.map((p) => p._id !== action.payload._id ? p : action.payload),
      adminProducts: updateProductList(state.adminProducts, action.payload),
      availableProducts: updateAvailableProducts(state.availableProducts, action.payload)
    }),
    addProduct: (state, action: PayloadAction<Product>) => ({
      ...state,
      adminProducts: [
        ...state.products || [],
        action.payload
      ],
      allProducts: [
        ...state.allProducts || [],
        action.payload
      ],
      availableProducts: action.payload.state === ProductState.Published ? [
        ...state.availableProducts || [],
        action.payload
      ] : state.availableProducts || [],
    }),
    addUnitType: (state, action: PayloadAction<UnitType>) => ({
      ...state,
      unitTypes: [
        ...state.unitTypes || [],
        action.payload
      ]
    }),
    removeUnitType: (state, action: PayloadAction<string>) => ({
      ...state,
      unitTypes: state.unitTypes && state.unitTypes.filter((u) => u._id !== action.payload)
    }),
    refreshLocations: (state, action: PayloadAction<SessionState['locations']>) => {
      return {
        ...state,
        locations: action.payload
      };
    },
    setRepeatOrders: (state, action: PayloadAction<boolean>) => {
      if (!state.me) return state;
      return {
        ...state,
        me: {
          ...state.me,
          repeatOrders: action.payload,
        }
      };
    }
  }
});


export const {
  loadInitialData,
  reset,
  patchProduct,
  addProduct,
  addUnitType,
  removeUnitType,
  refreshLocations,
  setRepeatOrders
} = sessionSlice.actions;

export default sessionSlice.reducer;
