import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';

import logo from '../assets/logo-small.png';
import { GREEN } from '../constants';
import { useSession } from '../hooks/useSession';
import { logout as logoutAction } from '../store/slices/authSlice';
import { reset } from '../store/slices/sessionSlice';
import { Omit, Role } from '../types';
import { isRole } from '../utils/isRole';
import { switchRole } from '../utils/switchRole';

import { Button } from './Button';
import { Placeholder } from './Placeholder';
import { Colors } from '../constants/colors';

type MenuItem = {
  title: string;
  path: string;
  sub: Array<Omit<MenuItem, 'sub'>>;
};

export function Navigation() {
  const { session: sessionState } = useSession();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const logout = async () => {
    dispatch(reset());
    dispatch(logoutAction());

    history.push('/');
    toast.success('Je bent succesvol uitgelogd');
  };

  const placeholders = Array(3).fill({});
  const menuItems = switchRole<MenuItem[]>(sessionState.me, {
    [Role.Location]: [
      {
        title: '',
        path: '',
        sub: [
          {
            title: 'Bestellingen',
            path: '',
          },
          {
            title: 'Instellingen',
            path: '/settings',
          },
        ],
      },
    ],
    [Role.Company]: [
      {
        title: '',
        path: '',
        sub: [
          {
            title: 'Bestellingen',
            path: '',
          },
          {
            title: 'Locaties',
            path: '/locations',
          },
          {
            title: 'Instellingen',
            path: '/settings',
          },
        ],
      },
    ],
    [Role.Admin]: [
      {
        title: 'Bestellingen',
        path: '',
        sub: [
          {
            title: 'Bestellingen per dag',
            path: '',
          },
          {
            title: 'Bestellingen per locatie',
            path: '/locations',
          },
          {
            title: 'Aantal producten',
            path: '/amount',
          },
          {
            title: 'Bestellingen definitief maken',
            path: '/finalize',
          },
        ],
      },
      {
        title: 'Prijzen',
        path: '/prices',
        sub: [],
      },
      {
        title: 'Producten',
        path: '/products',
        sub: [],
      },
      {
        title: 'Organisaties',
        path: '/companies',
        sub: [
          {
            title: 'Organisaties en locaties',
            path: '/companies',
          },
          {
            title: 'Locatievolgorde per dag',
            path: '/delivery',
          },
          {
            title: 'Mail versturen',
            path: '/send-email',
          },
          {
            title: 'Facturen',
            path: '/invoices',
          },
        ],
      },
      {
        title: 'Instellingen',
        path: '/settings',
        sub: [],
      },
    ],
  });
  const activeMenu =
    menuItems &&
    menuItems.find((menu) => {
      if (location.pathname === `/admin${menu.path}`) {
        return true;
      }

      return menu.sub.some((sub) => location.pathname === `/admin${sub.path}`);
    });

  return (
    <StyledNavigation>
      <StyledTopBar>
        <StyledTitle>
          <StyledLogo src={logo} />
          {sessionState.me ? (
            <>
              {sessionState.me.name}
              <StyledTag>
                {switchRole(sessionState.me, {
                  [Role.Location]: 'Locatie',
                  [Role.Company]: 'Organisatie',
                  [Role.Admin]: 'Admin',
                })}
              </StyledTag>
              <StyledNavBar>
                {menuItems &&
                  menuItems
                    .filter((item) => item.title)
                    .map((item) => (
                      <Button
                        key={item.path}
                        mode="text"
                        theme="dark"
                        onClick={() => history.push(`/admin${item.path}`)}
                      >
                        <StyledButtonText
                          color="white"
                          active={item === activeMenu}
                        >
                          {item.title}
                        </StyledButtonText>
                      </Button>
                    ))}
              </StyledNavBar>
            </>
          ) : (
            <Placeholder width="20rem" />
          )}
        </StyledTitle>
        <StyledRight>
          {isRole(sessionState.me, Role.Company) && (
            <StyledCode>Organisatiecode: {sessionState.me!.code}</StyledCode>
          )}
          <Button mode="text" theme="dark" onClick={logout}>
            Uitloggen
          </Button>
        </StyledRight>
      </StyledTopBar>
      {!(activeMenu && !activeMenu.sub.length) && (
        <StyledBottomBar>
          {activeMenu
            ? activeMenu.sub.map((item) => (
                <Button
                  key={item.path}
                  mode="text"
                  onClick={() => history.push(`/admin${item.path}`)}
                >
                  <StyledButtonText
                    color="black"
                    active={location.pathname === `/admin${item.path}`}
                  >
                    {item.title}
                  </StyledButtonText>
                </Button>
              ))
            : placeholders.map((_, index) => (
                <StyledPlaceholder key={index}>
                  <Placeholder width="7rem" />
                </StyledPlaceholder>
              ))}
        </StyledBottomBar>
      )}
    </StyledNavigation>
  );
}

const StyledNavigation = styled.div`
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
`;

const StyledTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  color: white;
  background-color: ${Colors.darkGreen};
  height: 4.5rem;
`;

const StyledTitle = styled.h1`
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
`;

const StyledBottomBar = styled.div`
  display: flex;
  padding: 0 3rem;
  background-color: white;
  height: 3.5rem;
  align-items: center;
  > * + * {
    margin-left: 1rem;
  }
`;

const StyledNavBar = styled.div`
  display: inline-block;
  > * + * {
    margin-left: 1rem;
  }
`;

const StyledTag = styled.span`
  margin: 0 1.5rem 0 0.5rem;
  color: ${GREEN};
  background-color: white;
  border-radius: 0.5rem;
  font-size: 0.5rem;
  padding: 0.125rem 0.25rem;
  vertical-align: super;
`;

const StyledRight = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLogo = styled.img`
  height: 28px;
  margin-right: 16px;
`;

const StyledButtonText = styled.span<{ color: string; active: boolean }>`
  ${(props) =>
    props.active &&
    css`
      color: ${props.color};
    `};
`;

const StyledPlaceholder = styled.div`
  padding: 0.75rem 0;
`;

const StyledCode = styled.div`
  margin-right: 0.5rem;
`;
