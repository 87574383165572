import { API_URL } from '../constants';
import { Method } from '../types';

import { sleep } from './sleep';

export async function createRequest<R, P = unknown>(url: string, method = Method.Get, token?: string, payload?: P) {
  const [response] = await Promise.all<any>([
    fetch(`${API_URL}${url}`, {
      method,
      body: payload instanceof FormData ? payload : JSON.stringify(payload),
      headers: {
        ...!(payload instanceof FormData) && {
          'Content-Type': 'application/json'
        },
        ...token && {
          Authorization: `Bearer ${token}`
        }
      } as Record<string, string>
    }),
    sleep(method === Method.Get ? 500 : 0)
  ]);

  if (!response.ok) {
    throw response;
  }

  if (method === Method.Delete || response.status === 204) {
    return undefined!;
  }

  return response.json() as R;
}
