import { keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

type Props = {
  count?: number,
  width?: string
};

export function Placeholder({ count = 1, width = '100%' }: Props) {
  return (
    <>
      {Array(count).fill(undefined).map((_, index) => (
        <StyledPlaceholder key={index} width={width}/>
      ))}
    </>
  );
}

const load = keyframes`
  from {
    background-position: -200px 0;
  }
  to {
    background-position: calc(100% + 200px) 0;
  }
`;

const StyledPlaceholder = styled.span<{ width: string }>`
  display: block;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: .25rem;
  width: ${(props) => props.width};
  height: 1em;
  animation: ${load} .75s linear infinite;
`;
