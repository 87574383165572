import styled from '@emotion/styled/macro';
import React, { ReactNode } from 'react';

import { Breakpoints } from '../types';
import { forBreakpoints } from '../utils/forBreakpoints';

type Props = {
  children: ReactNode,
  breakpoints: Breakpoints<number>
};

export function Column({ children, breakpoints, ...props }: Props) {
  return (
    <StyledColumn {...props} breakpoints={breakpoints}>
      {children}
    </StyledColumn>
  );
}

const StyledColumn = styled.div<{ breakpoints: Breakpoints<number> }>`
  flex: 0 0 100%;
  ${(props) => forBreakpoints(props.breakpoints, (value) => `
     flex-basis: ${100 / 12 * value}%;
  `)};
`;
