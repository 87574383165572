import styled from '@emotion/styled/macro';
import React, { ComponentType } from 'react';
import { Key as Keyboard } from 'ts-key-enum';

import { OVERLAYS } from '../constants';
import { useKeyHandler } from '../hooks/useKeyHandler';
import { useOverlay } from '../states/overlay';
import { Overlay } from '../types';

export function Overlays() {
  const [overlayState, overlayActions] = useOverlay();

  useKeyHandler(Keyboard.Escape, overlayActions.closeOverlay);

  const visible = overlayState.visible !== undefined;

  return (
    <StyledOverlays visible={visible}>
      <StyledBackdrop visible={visible} onClick={overlayActions.closeOverlay}/>
      {Object.keys(overlayState.overlays).map((value) => {
        const key = value as Overlay;
        const Component = OVERLAYS[key] as ComponentType<any>;
        const props = overlayState.visible === key ? overlayState.overlays[key].props : undefined;

        return (
          <Component key={key} {...props as any} visible={overlayState.visible === key}/>
        );
      })}
    </StyledOverlays>
  );
}

const StyledOverlays = styled.div<{ visible: boolean }>`
  pointer-events: ${(props) => props.visible ? 'auto' : 'none'};
  z-index: 100;
`;

const StyledBackdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .5);
  opacity: ${(props) => props.visible ? 1 : 0};
  transition: opacity .25s ease;
`;
